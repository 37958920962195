import { template as template_4773b2b540fc448c9d42730c78e790ce } from "@ember/template-compiler";
const SidebarSectionMessage = template_4773b2b540fc448c9d42730c78e790ce(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
