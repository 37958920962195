import { template as template_e15c3f5d312c45788eee7485262487ef } from "@ember/template-compiler";
const FKText = template_e15c3f5d312c45788eee7485262487ef(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
