import { template as template_0f2a8d4df13d4acb91be2af3b5457f22 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0f2a8d4df13d4acb91be2af3b5457f22(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
