import { template as template_62e90332684f4de8845765ae84b09026 } from "@ember/template-compiler";
const FKLabel = template_62e90332684f4de8845765ae84b09026(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
